import styled from "styled-components";

export const StyledSpan = styled.span`
  /* font-family: "Roboto Condensed", sans-serif; */
  font-weight: 400;
  display: block;
  margin-top: -10px;
  font-size: 14px;
  color: white;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export const StyledParagraph = styled.p`
  margin: 4px 0 0;
  font-weight: 400;
  /* display: block; */
  font-size: 16px;
  color: white;
  white-space: nowrap;

  @media (max-width: 767px) {
    font-size: 14px;
  }

  @media print {
    color: black;
  }
`;

export const StyledParagraphAdjustment = styled.p`
  margin: 0;
  font-weight: 400;
  display: block;
  font-size: 16px;
  color: red;

  @media (max-width: 767px) {
      font-size: 12px;
  }

  @media print {
    color: black;
  }
`;
