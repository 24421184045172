import axios from "axios";
import moment from "moment/moment";
import { useState, useEffect } from "react";
import { StyledParagraph } from "../LastDay/styled";
import { baseUrl } from "../apiUrl";

const DatesLastStatment = () => {
  const [lastStatment, setLastStatment] = useState({
    date_from: "",
    date_to: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/datesLastStatment.php`
        );
        
        const lastStatment = response.data;
        setLastStatment({
          date_from: lastStatment.date_from,
          date_to: lastStatment.date_to,
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const date_from = lastStatment.date_from;
  const date_to = lastStatment.date_to;

  const FormattedDateFrom = moment(date_from)
    .locale("en-gb")
    .format("YYYY-MM-DD");

  const FormattedDateTo = moment(date_to).locale("en-gb").format("YYYY-MM-DD");

  return (
    <>
      <div className="box__latestSummary">
        <StyledParagraph>
          <span className="text-primary"> Latest summary sent:</span><br />
          &nbsp;from&nbsp;
          {FormattedDateFrom} to&nbsp;
          {FormattedDateTo}
        </StyledParagraph>
        <br />
      </div>
    </>
  )
};

export default DatesLastStatment;
