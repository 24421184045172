import { useState, useEffect } from "react"
import { MainWrapper } from "../../common/MainWrapper"
import { StyledMainTitle } from "../styled"
import { Link, useNavigate } from "react-router-dom"
import Header from "../../common/Header"

const Panel = ({ user }) => {
  const [auth, setAuth] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    const auth = localStorage.getItem("UserName")
    setAuth(auth)
    if (user === "true") {
      navigate("/panel")
    }
  }, [user, navigate])

  return (
    <>
      <Header user={user} />
      <MainWrapper>
        <StyledMainTitle>Working Time Records</StyledMainTitle>
        <h5 className="text-primary m-2">Hello, {auth}</h5>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <Link
              to="/insday"
              className="col-md-2 btn btn-primary btn-lg m-2 p-3"
              role="button"
            >
              Insert Work Day
            </Link>
            <Link
              to="/result"
              className="col-md-2 btn btn-primary btn-lg m-2 p-3"
              role="button"
            >
              Show earnings
            </Link>
           
            <Link
              to="/statments"
              className="col-md-2 btn btn-primary btn-lg m-2 p-3"
              role="button"
            >
              Show Statments
            </Link>
          </div>
        </div>
      </MainWrapper>
    </>
  );
}

export default Panel
