import { useState } from "react";
import { MainWrapper } from "../../common/MainWrapper";
import { StyledTable, TableWrapper, StyledMainTitle } from "../styled";
import Header from "../../common/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import LastDay from "../../common/LastDay/index.js";
import DatesLastStatment from "../../common/DatesLastStatment";
import {
  StyledParagraph,
  StyledParagraphAdjustment,
} from "../../common/LastDay/styled";
import "../../style.css";
import ScrollButton from "../scrollButton";
import TodayDate from "../../common/todayDate";
import { BackToPanel } from "../../common/BackToPanel";
import { useResult } from "../../common/useResult";
import MailSender from "../../common/MailSender";
import { baseUrl } from "../../common/apiUrl";

const Result = () => {
  const navigate = useNavigate();
  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const item = useResult({ data1, data2 });
  const [bonus_percentage, setBonus_Percentage] = useState(0.00);
  const [extra_amount, setExtra_Amount] = useState(0.00);

  const [dateLastStatment, setDateLastStatment] = useState(false);
  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const handleChangeData1 = (e) => {
    setData1(e.target.value);
  };

  const handleChangeData2 = (e) => {
    setData2(e.target.value);
  };

  const handleChangeBox = ({ target }) => {
    setDateLastStatment(target.checked);
  };

  const handleChangeBonus = (e) => {
    setBonus_Percentage(e.target.value);
  }

  const handleChangeExtraAmount = (e) => {
    setExtra_Amount(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setIsSubmitForm(true);

    const datesLastStatment = {
      data1: data1,
      data2: data2,
      dateLastStatment: dateLastStatment,
      bonus_percentage: bonus_percentage,
      extra_amount: extra_amount,
    };

    axios
      .post(`${baseUrl}/insLastStatment.php`, datesLastStatment)
      .then((result) => {
        if (result.data.Status === "Invalid") {
          alert("Invalid Data");
        } else {
          navigate("/result");

        }
      });
  };

  let totalRegularHours = 0;
  let regularHoursRate = 0;
  let erningFromRegularHours = 0;

  let totalRegularHours24072023 = 0;
  let regularHoursRate24072023 = 0;
  let erningFromRegularHours24072023 = 0;

  let totalOperatorHours = 0;
  let operatorHoursRate = 0;
  let erningFromOperatorHours = 0;

  let totalOperatorHours24072023 = 0;
  let operatorHoursRate24072023 = 0;
  let erningFromOperatorHours24072023 = 0;

  let totalOperatorHoursWeekend23102022 = 0;
  let operatorHoursWeekend23102022Rate = 0;
  let erningFromOperatorHoursWeekend23102022 = 0;

  let totalOperatorHoursWeekend24072023 = 0;
  let operatorHoursWeekendRate24072023 = 0;
  let erningFromOperatorHoursWeekend24072023 = 0;

  let totalOperatorHoursWeekend = 0;
  let operatorHoursWeekendRate = 0;
  let erningFromOperatorHoursWeekend = 0;

  let totalRegularHoursWeekend = 0;
  let regularHoursWeekendRate = 0;
  let erningFromRegularHoursWeekend = 0;

  let totalOperatorHoursHoliday = 0;
  let operatorHoursHolidayRate = 0;
  let erningFromOperatorHoursHoliday = 0;

  let totalRegularHoursHoliday = 0;
  let regularHoursHolidayRate = 0;
  let erningFromRegularHoursHoliday = 0;

  let totalRegularHoursCorrection = 0;
  let regularHoursCorrectionRate = 0;
  let erningFromRegularHoursCorrection = 0;

  let totalOperatorHoursCorrection = 0;
  let operatorHoursCorrectionRate = 0;
  let erningFromOperatorHoursCorrection = 0;

  let totalHours = 0;
  let totalErn = 0.00;
  let bonusAmount = 0.00;
  // let parsedExtraAmount = 0.00;
  let totalErnWithBonus = 0.00;

  const parsedExtraAmount = parseFloat(extra_amount);

  const FormattedData1 = moment(data1).locale("en-gb").format("YYYY-MM-DD");
  const FormattedData2 = moment(data2).locale("en-gb").format("YYYY-MM-DD");

  return (
    <>
      <Header />
      <ScrollButton />
      <MainWrapper>
        <StyledMainTitle>Select a date range to calculate</StyledMainTitle>
        <div className="container">
          <form onSubmit={submitForm}>
            <StyledTable>
              <tbody>
                <tr>
                  <td className="float-end me-2 text-primary p-2">
                    Date from:{" "}
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control m-1"
                      name="data1"
                      id="data1"
                      onChange={handleChangeData1}
                      value={data1}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="float-end me-2 text-primary p-2">Date to: </td>
                  <td>
                    <input
                      type="date"
                      className="form-control m-1"
                      name="data2"
                      id="data2"
                      onChange={handleChangeData2}
                      value={data2}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="float-end me-2 text-primary p-2">
                    Add bonus percentage:&nbsp;
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.01"
                      className="form-control m-1"
                      name="bonus_percentage"
                      id="bonus_percentage"
                      title="Wprowadź liczbę w formacie: 10.00"
                      inputMode="decimal"
                      onChange={handleChangeBonus}
                      value={bonus_percentage}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="float-end me-2 text-primary p-2">
                    Extra amount:&nbsp;
                  </td>
                  <td>
                    <input
                      type="number"
                      step="0.01"
                      className="form-control m-1"
                      name="extraAmount"
                      id="extraAmount"
                      title="Wprowadź liczbę w formacie: 10.00"
                      inputMode="decimal"
                      onChange={handleChangeExtraAmount}
                      value={extra_amount}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="float-end text-primary p-2">
                    Save last statement:&nbsp;
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input float-start m-1"
                      name="dateLastStatment"
                      checked={dateLastStatment}
                      onChange={handleChangeBox}
                    />
                  </td>
                </tr>
              </tbody>
            </StyledTable>

            <TableWrapper>
              <button
                type="submit"
                name="submit"
                className="btn btn-primary btn-lg m-2"
                disabled={isSubmitForm}
              >
                {isSubmitForm ? "Done!" : "Show Result"}
              </button>
              <button
                type="reset"
                className="btn btn-primary btn-lg m-2"
                onClick={(e) => window.location.reload()}
              >
                Clear
              </button>
            </TableWrapper>
          </form>
        </div>
        <BackToPanel />
        <LastDay />
        <DatesLastStatment />
        {isSubmitForm && (
          <>
            <p className="onlyPrint__header">
              WTR - Working Time Records
              <br />
              <small>Radek Bubienko</small>
            </p>
            <p className="statment__from no-wrap">
              Statement from:<br /><b>{FormattedData1}</b> to <b>{FormattedData2}</b>
            </p>
            <TableWrapper>
              <table className="table table-dark table-striped table-bordered table-modified">
                <thead>
                  <tr>
                    <td>Date</td>
                    <td>Shift</td>
                    <td>
                      Hours
                      <br />
                      worked
                    </td>
                    <td>
                      Rate
                      <br />
                      per hour
                    </td>
                    <td>
                      Daily
                      <br />
                      earnings
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {item.map((item) => {
                    const FormattedDataPracy = moment(item.data_pracy)
                      .locale("en-gb")
                      .format("YYYY.MM.DD - dddd");

                    const parseIlGodz = parseFloat(item.il_godzin);

                    if (
                      item.typ_stawki === "regular" &&
                      item.data_od === "2022-07-01"
                    ) {
                      totalRegularHours += parseIlGodz;
                      regularHoursRate = item.stawka;
                      erningFromRegularHours =
                        totalRegularHours * regularHoursRate;
                    }

                    if (
                      item.typ_stawki === "regular" &&
                      item.data_od === "2023-07-24"
                    ) {
                      totalRegularHours24072023 += parseIlGodz;
                      regularHoursRate24072023 = item.stawka;
                      erningFromRegularHours24072023 =
                        totalRegularHours24072023 * regularHoursRate24072023;
                    }

                    if (
                      item.typ_stawki === "operator" &&
                      item.data_od === "2024-01-01"
                    ) {
                      totalOperatorHours += parseIlGodz;
                      operatorHoursRate = item.stawka;
                      erningFromOperatorHours =
                        totalOperatorHours * operatorHoursRate;
                    }

                    if (
                      item.typ_stawki === "operator" &&
                      item.data_od === "2023-07-24"
                    ) 
                    {
                      totalOperatorHours24072023 += parseIlGodz;
                      operatorHoursRate24072023 = item.stawka;
                      erningFromOperatorHours24072023 =
                        totalOperatorHours24072023 * operatorHoursRate24072023;
                    }

                    if (
                      item.typ_stawki === "operator weekend" &&
                      item.data_od === "2022-07-01"
                    ) {
                      totalOperatorHoursWeekend23102022 += parseIlGodz;
                      operatorHoursWeekend23102022Rate = item.stawka;
                      erningFromOperatorHoursWeekend23102022 =
                        totalOperatorHoursWeekend23102022 *
                        operatorHoursWeekend23102022Rate;
                    }

                    if (
                      item.typ_stawki === "operator weekend" &&
                      item.data_od === "2024-01-01"
                    ) {
                      totalOperatorHoursWeekend += parseIlGodz;
                      operatorHoursWeekendRate = item.stawka;
                      erningFromOperatorHoursWeekend =
                        totalOperatorHoursWeekend * operatorHoursWeekendRate;
                    }

                    if (
                      item.typ_stawki === "operator weekend" &&
                      item.data_od === "2023-07-24"
                    ) {
                      totalOperatorHoursWeekend24072023 += parseIlGodz;
                      operatorHoursWeekendRate24072023 = item.stawka;
                      erningFromOperatorHoursWeekend24072023 =
                        totalOperatorHoursWeekend24072023 *
                        operatorHoursWeekendRate24072023;
                    }

                    if (item.typ_stawki === "regular weekend") {
                      totalRegularHoursWeekend += parseIlGodz;
                      regularHoursWeekendRate = item.stawka;
                      erningFromRegularHoursWeekend =
                        totalRegularHoursWeekend * regularHoursWeekendRate;
                    }

                    if (item.typ_stawki === "operator holiday") {
                      totalOperatorHoursHoliday += parseIlGodz;
                      operatorHoursHolidayRate = item.stawka;
                      erningFromOperatorHoursHoliday =
                        totalOperatorHoursHoliday * operatorHoursHolidayRate;
                    }

                    if (item.typ_stawki === "regular holiday") {
                      totalRegularHoursHoliday += parseIlGodz;
                      regularHoursHolidayRate = item.stawka;
                      erningFromRegularHoursHoliday =
                        totalRegularHoursHoliday * regularHoursHolidayRate;
                    }

                    if (item.typ_stawki === "regular correction") {
                      totalRegularHoursCorrection += parseIlGodz;
                      regularHoursCorrectionRate = item.stawka;
                      erningFromRegularHoursCorrection =
                        totalRegularHoursCorrection *
                        regularHoursCorrectionRate;
                    }

                    if (item.typ_stawki === "operator correction") {
                      totalOperatorHoursCorrection += parseIlGodz;
                      operatorHoursCorrectionRate = item.stawka;
                      erningFromOperatorHoursCorrection =
                        totalOperatorHoursCorrection *
                        operatorHoursCorrectionRate;
                    }

                    totalHours =
                      totalRegularHours +
                      totalRegularHours24072023 +
                      totalOperatorHours +
                      totalOperatorHours24072023 +
                      totalOperatorHoursWeekend23102022 +
                      totalOperatorHoursWeekend24072023 +
                      totalOperatorHoursWeekend +
                      totalRegularHoursWeekend +
                      totalOperatorHoursHoliday +
                      totalRegularHoursHoliday -
                      totalRegularHoursCorrection -
                      totalOperatorHoursCorrection;

                    totalErn =
                      erningFromRegularHours +
                      erningFromRegularHours24072023 +
                      erningFromOperatorHours +
                      erningFromOperatorHours24072023 +
                      erningFromOperatorHoursWeekend23102022 +
                      erningFromOperatorHoursWeekend +
                      erningFromOperatorHoursWeekend24072023 +
                      erningFromRegularHoursWeekend +
                      erningFromOperatorHoursHoliday +
                      erningFromRegularHoursHoliday -
                      erningFromRegularHoursCorrection -
                      erningFromOperatorHoursCorrection;

                    bonusAmount = (totalErn * bonus_percentage) / 100;

                    totalErnWithBonus =
                      bonusAmount + totalErn + parsedExtraAmount;

                    return (
                      <tr key={item.id_daty}>
                        <td>{FormattedDataPracy}</td>
                        <td>{item.zmiana}</td>
                        <td>{item.il_godzin}</td>
                        <td>
                          {item.typ_stawki} - {item.stawka} PLN
                        </td>
                        <td>{item.il_godzin * item.stawka} PLN</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableWrapper>
            <StyledParagraph className="mb-2">
              Please verify the hours for the period:
              <br /> from <b>{FormattedData1}</b> to <b>{FormattedData2}</b>
            </StyledParagraph>
            {totalRegularHours > 0 ? (
              <StyledParagraph>
                Number of regular hours:
                <br /> {totalRegularHours.toFixed(1)} h x&nbsp;
                {regularHoursRate} PLN = {erningFromRegularHours.toFixed(2)}{" "}
                PLN.
              </StyledParagraph>
            ) : null}
            {totalRegularHours24072023 > 0 ? (
              <StyledParagraph>
                Number of regular hours:
                <br /> {totalRegularHours24072023.toFixed(1)} h x&nbsp;
                {regularHoursRate24072023} PLN =&nbsp;
                {erningFromRegularHours24072023.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHours > 0 ? (
              <StyledParagraph>
                Number of operator hours:
                <br />
                {totalOperatorHours.toFixed(1)} h x&nbsp;{operatorHoursRate} PLN
                =&nbsp;
                {erningFromOperatorHours.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHours24072023 > 0 ? (
              <StyledParagraph>
                Number of operator hours till 2023-12-31:
                <br />
                {totalOperatorHours24072023.toFixed(1)} h x&nbsp;
                {operatorHoursRate24072023} PLN =&nbsp;
                {erningFromOperatorHours24072023.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHoursWeekend23102022 > 0 ? (
              <StyledParagraph>
                Number of weekend operator hours till 23.10.2022:
                <br />
                {totalOperatorHoursWeekend23102022.toFixed(1)} h x&nbsp;
                {operatorHoursWeekend23102022Rate} PLN =&nbsp;
                {erningFromOperatorHoursWeekend23102022.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHoursWeekend > 0 ? (
              <StyledParagraph>
                Number of weekend operator hours:
                <br />
                {totalOperatorHoursWeekend.toFixed(1)} h x&nbsp;
                {operatorHoursWeekendRate} PLN =&nbsp;
                {erningFromOperatorHoursWeekend.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHoursWeekend24072023 > 0 ? (
              <StyledParagraph>
                Number of weekend operator hours:
                <br />
                {totalOperatorHoursWeekend24072023.toFixed(1)} h x&nbsp;
                {operatorHoursWeekendRate24072023} PLN =&nbsp;
                {erningFromOperatorHoursWeekend24072023.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalRegularHoursWeekend > 0 ? (
              <StyledParagraph>
                Number of weekend regular hours:
                <br />
                {totalRegularHoursWeekend.toFixed(1)} h x&nbsp;
                {regularHoursWeekendRate}
                &nbsp; PLN =&nbsp;{erningFromRegularHoursWeekend.toFixed(2)}
                PLN.
              </StyledParagraph>
            ) : null}
            {totalRegularHoursHoliday > 0 ? (
              <StyledParagraph>
                Number of operator hours on a holiday:
                <br />
                {totalRegularHoursHoliday.toFixed(1)} h x&nbsp;
                {regularHoursHolidayRate} PLN =&nbsp;
                {erningFromRegularHoursHoliday.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHoursHoliday > 0 ? (
              <StyledParagraph>
                Number of operator hours on a holiday:
                <br />
                {totalOperatorHoursHoliday.toFixed(1)} h x&nbsp;
                {operatorHoursHolidayRate} PLN =&nbsp;
                {erningFromOperatorHoursHoliday.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalRegularHoursCorrection > 0 ? (
              <StyledParagraphAdjustment>
                Number of regular hours - correction:
                <br />
                {totalRegularHoursCorrection.toFixed(1)} h x&nbsp;
                {regularHoursCorrectionRate} PLN =&nbsp;
                {erningFromRegularHoursCorrection.toFixed(2)} PLN.
              </StyledParagraphAdjustment>
            ) : null}
            {totalOperatorHoursCorrection > 0 ? (
              <StyledParagraphAdjustment>
                Number of operator hours - correction:
                <br />
                {totalOperatorHoursCorrection.toFixed(1)} h x&nbsp;
                {operatorHoursCorrectionRate} PLN =&nbsp;
                {erningFromOperatorHoursCorrection.toFixed(2)} PLN.
              </StyledParagraphAdjustment>
            ) : null}
            {totalHours > 0 ? (
              <StyledParagraph>
                <br />
                Total number of hours: <b>{totalHours.toFixed(1)}</b> h.
              </StyledParagraph>
            ) : null}
            {totalErn > 0 ? (
              <StyledParagraph>
                Total to pay: <b>{totalErn.toFixed(2)}</b> PLN/netto.
                <br />
              </StyledParagraph>
            ) : null}
            {extra_amount > 0 ? (
              <StyledParagraph>
                Extra amount:
                <b>{parsedExtraAmount.toFixed(2)}</b> PLN/netto.
                <br />
              </StyledParagraph>
            ) : null}
            {bonus_percentage > 0 ? (
              <StyledParagraph>
                Bonus <b>{bonus_percentage}</b>% = <b>{bonusAmount.toFixed(2)}</b> PLN/netto.
              </StyledParagraph>
            ) : null}
            {bonus_percentage > 0 || extra_amount > 0 ? (
              <StyledParagraph>
                Total to pay with bonuses:
                <br /> <b>{totalErnWithBonus.toFixed(2)}</b>
                PLN/netto.
              </StyledParagraph>
            ) : null}
          </>
        )}

        {totalErn > 0 ? (
          <>
            <div className="container-fluid d-print-none">
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-lg mt-3 m-2 col-md-2"
                  onClick={() => window.print()}
                >
                  Print
                </button>

                <MailSender
                  date_from={data1}
                  date_to={data2}
                  totalRegularHours={totalRegularHours}
                  regularHoursRate={regularHoursRate}
                  erningFromRegularHours={erningFromRegularHours}
                  totalRegularHours24072023={totalRegularHours24072023}
                  regularHoursRate24072023={regularHoursRate24072023}
                  erningFromRegularHours24072023={
                    erningFromRegularHours24072023
                  }
                  totalOperatorHours={totalOperatorHours}
                  operatorHoursRate={operatorHoursRate}
                  erningFromOperatorHours={erningFromOperatorHours}
                  totalOperatorHours24072023={totalOperatorHours24072023}
                  operatorHoursRate24072023={operatorHoursRate24072023}
                  erningFromOperatorHours24072023={
                    erningFromOperatorHours24072023
                  }
                  totalOperatorHoursWeekend23102022={
                    totalOperatorHoursWeekend23102022
                  }
                  operatorHoursWeekend23102022Rate={
                    operatorHoursWeekend23102022Rate
                  }
                  erningFromOperatorHoursWeekend23102022={
                    erningFromOperatorHoursWeekend23102022
                  }
                  totalOperatorHoursWeekend24072023={
                    totalOperatorHoursWeekend24072023
                  }
                  operatorHoursWeekendRate24072023={
                    operatorHoursWeekendRate24072023
                  }
                  erningFromOperatorHoursWeekend24072023={
                    erningFromOperatorHoursWeekend24072023
                  }
                  totalOperatorHoursWeekend={totalOperatorHoursWeekend}
                  operatorHoursWeekendRate={operatorHoursWeekendRate}
                  erningFromOperatorHoursWeekend={
                    erningFromOperatorHoursWeekend
                  }
                  totalRegularHoursWeekend={totalRegularHoursWeekend}
                  regularHoursWeekendRate={regularHoursWeekendRate}
                  erningFromRegularHoursWeekend={erningFromRegularHoursWeekend}
                  totalRegularHoursHoliday={totalRegularHoursHoliday}
                  regularHoursHolidayRate={regularHoursHolidayRate}
                  erningFromRegularHoursHoliday={erningFromRegularHoursHoliday}
                  totalOperatorHoursHoliday={totalOperatorHoursHoliday}
                  operatorHoursHolidayRate={operatorHoursHolidayRate}
                  erningFromOperatorHoursHoliday={
                    erningFromOperatorHoursHoliday
                  }
                  totalRegularHoursCorrection={totalRegularHoursCorrection}
                  regularHoursCorrectionRate={regularHoursCorrectionRate}
                  erningFromRegularHoursCorrection={
                    erningFromRegularHoursCorrection
                  }
                  totalOperatorHoursCorrection={totalOperatorHoursCorrection}
                  operatorHoursCorrectionRate={operatorHoursCorrectionRate}
                  erningFromOperatorHoursCorrection={
                    erningFromOperatorHoursCorrection
                  }
                  totalErn={totalErn}
                  totalHours={totalHours}
                  bonus_percentage={bonus_percentage}
                  bonusAmount={bonusAmount}
                  extra_amount={extra_amount}
                  totalErnWithBonus={totalErnWithBonus}
                />
              </div>
            </div>
          </>
        ) : null}

        <div className="printDate">
          <TodayDate />
        </div>
      </MainWrapper>
    </>
  );
};

export default Result;
