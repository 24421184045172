import { NavLink } from "react-router-dom";
import { toAllDays, toInvoicesList, toPanel, toResult, toStatmentsList } from "../../App/routes";
import Logout from "../../pages/Logout/index.js";

const Header = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-primary">
      <div className="container-fluid ms-2">
        <NavLink to={toPanel} className="navbar-brand me-5">
          WTR
        </NavLink>
        <button
          className="navbar-toggler me-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to={toPanel} className="nav-link" aria-current="page">
                Panel
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to={toResult} className="nav-link">
                Result
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={toStatmentsList} className="nav-link">
                List Statments
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={toAllDays} className="nav-link">
                List All Days
              </NavLink>
            </li>
            
              <li className="nav-item">
                <NavLink to={toInvoicesList} className="nav-link">
                  Invoices List
                </NavLink>
              </li>
            
          </ul>
          <ul className="navbar-nav ms-auto me-5">
            <li className="nav-item">
              <Logout />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
