import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
        overflow-y: scroll;
    }

    *, ::after, ::before {
        box-sizing: inherit;
    }

    body {
        font-family: 'Open Sans', sans-serif;
        background: ${({ theme }) => theme.colors.woodsmoke};
        padding-bottom: 80px;
        line-height: 1.2;

        @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
            padding-bottom: 50px;
            font-family: 'Roboto Condensed', sans-serif;
        }

        @media print {
            background-color: white;
        }
    }

    .caps-lock-text {
        top: 110%;
        font-size: 18px;
        
    }

    .modal__inset {
        inset: 10px !important;
    }
`;
