import { useState } from "react";
import { MainWrapper } from "../../common/MainWrapper";
import { TableWrapper, LinkDelete, LinkShow, StyledMainTitle } from "../styled";
import Header from "../../common/Header";
import { useAllDays } from "../../common/useAllDays";
import ScrollButton from "../scrollButton";
import moment from "moment/moment";
import axios from "axios";
import { baseUrl } from "../../common/apiUrl";
import { BackToPanel } from "../../common/BackToPanel";

const AllDays = ({ user }) => {
  
  const item = useAllDays();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);

  const deleteAndRefresh = (id_daty) => {
    if (window.confirm(`Are you sure to delete this day?`)) {
      deleteDay(id_daty);
    }
  };

  const deleteDay = (id_daty) => {
    axios
      .delete(`${baseUrl}/deleteDay.php`, {
        data: { id_daty: id_daty },
      })
      .then(() => {
        reload();
      })
      .catch(() => {
        alert("Error in the Code");
      });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = item.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Generate page numbers
  const pageNumbers = [];
  const totalPages = Math.ceil(item.length / itemsPerPage);
  const maxVisiblePages = 3;
  let startPage, endPage;

  if (totalPages <= maxVisiblePages) {
    // Display all pages if total pages are less than or equal to max visible pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // Calculate start and end page to limit the number of visible pages
    const maxVisiblePagesBeforeCurrent = Math.floor(maxVisiblePages / 2);
    const maxVisiblePagesAfterCurrent = Math.ceil(maxVisiblePages / 2) - 1;

    if (currentPage <= maxVisiblePagesBeforeCurrent) {
      // Current page is near the start
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (currentPage + maxVisiblePagesAfterCurrent >= totalPages) {
      // Current page is near the end
      startPage = totalPages - maxVisiblePages + 1;
      endPage = totalPages;
    } else {
      // Current page is in the middle
      startPage = currentPage - maxVisiblePagesBeforeCurrent;
      endPage = currentPage + maxVisiblePagesAfterCurrent;
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const reload = () => {
    window.location.reload();
  };


  return (
    <>
      <Header user={user} />
      <ScrollButton />
      <MainWrapper>
        <StyledMainTitle>List All Days</StyledMainTitle>
        <BackToPanel />
        <TableWrapper>
          <table className="table table-dark table-striped table-bordered table-modified">
            <thead>
              <tr>
                <td>ID</td>
                <td>Date</td>
                <td>Shift</td>
                <td>
                  Hours
                  <br />
                  worked
                </td>
                <td>
                  Rate
                  <br />
                  per hour
                </td>
                <td>
                  Daily
                  <br />
                  earnings
                </td>
                <td>Edit</td>
                <td>Delete</td>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => {
                const FormattedDataPracy = moment(item.data_pracy)
                  .locale("en-gb")
                  .format("YYYY.MM.DD - dddd");

                return (
                  <tr key={item.id_daty}>
                    <td>{item.id_daty}</td>
                    <td>{FormattedDataPracy}</td>
                    <td>{item.zmiana}</td>
                    <td>{item.il_godzin}</td>
                    <td>{item.stawka} PLN</td>
                    <td>{item.il_godzin * item.stawka} PLN</td>
                    <td>
                      <LinkShow to={`/editday/${item.id_daty}`}>Edit</LinkShow>
                    </td>
                    <td>
                      <LinkDelete
                        to=""
                        onClick={() => deleteAndRefresh(item.id_daty)}
                      >
                        Delete
                      </LinkDelete>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableWrapper>
        <nav>
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
              >
                First
              </button>
            </li>
            {currentPage > 1 && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </button>
              </li>
            )}
            {pageNumbers.map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${
                  pageNumber === currentPage ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            {currentPage < totalPages && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            )}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </button>
            </li>
          </ul>
        </nav>
      </MainWrapper>
    </>
  );
};

export default AllDays;
