import axios from "axios";
import { StyledSpan } from "./styled";
import moment from "moment/moment";
import { useState, useEffect } from "react";
import { baseUrl } from "../apiUrl";

const LastDay = () => {
  
  const [lastDayData, setLastDayData] = useState({
    data_pracy: "",
    zmiana: "",
    il_godzin: 0,
    typ_stawki: "",
    stawka: 0,
  });

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/lastday.php`
        );

       const lastDayData = response.data;
        
        setLastDayData({
          data_pracy: lastDayData.data_pracy,
          zmiana: lastDayData.zmiana,
          il_godzin: lastDayData.il_godzin,
          typ_stawki: lastDayData.typ_stawki,
          stawka: lastDayData.stawka,
        });
      } catch (error) {
        console.error(error);
      }
      
    };
    fetchData();
  }, []);

  const data_pracy = lastDayData.data_pracy;
  const zmiana = lastDayData.zmiana;
  const il_godzin = lastDayData.il_godzin;
  const typ_stawki = lastDayData.typ_stawki;
  const stawka = lastDayData.stawka;

  const FormattedDataPracy = moment(data_pracy)
    .locale("en-gb")
    .format("YYYY.MM.DD - dddd");
  const ernDay = stawka * il_godzin;
  return (
    <>
      <div className="last__day">
        <p className="text-primary">Last day of work entered:</p>
        <StyledSpan>
          Date: {FormattedDataPracy} | {zmiana} | Number of hours: {il_godzin} <br />
          Rate: {typ_stawki} - {stawka} PLN. | Daily earnings: {ernDay} PLN.
        </StyledSpan>
      </div>
    </>
  );
};

export default LastDay;
