import { LinkBackTo } from "../pages/styled"
export const BackToPanel = () => {
  return (
    <div className="mt-1 mb-3">
      <LinkBackTo to="/panel">
        <button className="btn btn-outline-light btn-sm ps-3 pe-3">
          Back to Panel
        </button>
      </LinkBackTo>
    </div>
  );
}
