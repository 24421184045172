import Header from "../../common/Header";
import NumberInWords from "../../common/numberInWords";
import ScrollButton from "../scrollButton";
import "./style.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { DateTime } from "luxon";
import { baseUrl } from "../../common/apiUrl";
import { useParams, Link } from "react-router-dom";

const Invoice = () => {

  const { id } = useParams();

  const [invoiceData, setInvoiceData] = useState({
    id: "",
    invoiceDate: "",
    paymentDate: "",
    invoiceNumber: "",
    totalErnNetto: "",
    bonusAmount: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
      const response = await axios.get(`${baseUrl}/getInvoice.php?id=` + id);

        const invoiceData = response.data;

        setInvoiceData({
          id: invoiceData.id,
          invoiceDate: invoiceData.invoiceDate,
          paymentDate: invoiceData.paymentDate,
          invoiceNumber: invoiceData.invoiceNumber,
          totalErnNetto: invoiceData.totalErnNetto,
          bonusAmount: invoiceData.bonusAmount,
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  const invoiceDate = DateTime.fromISO(invoiceData.invoiceDate).toFormat('dd/MM/yyyy');
  const paymentDate = DateTime.fromISO(invoiceData.paymentDate).toFormat('dd/MM/yyyy');
  const invoiceNumber = invoiceData.invoiceNumber;
  const totalErnNetto = invoiceData.totalErnNetto;
  const bonusAmount = invoiceData.bonusAmount;

  const invoiceTotalErnNetto = +totalErnNetto + +bonusAmount;
  const invoiceTotalErnBrutto = invoiceTotalErnNetto * 1.23;
  const invoiceTotalErnVat = invoiceTotalErnNetto * 1.23 - invoiceTotalErnNetto;

  return (
    <>
      <Header />
      <div className="container-fluid d-print-none">
        <div className="row justify-content-center">
          <button
            className="btn btn-primary mt-3 m-2 col-md-2"
            onClick={() => window.print()}
          >
            Print
          </button>
          <Link
            to="/invoiceslist"
            className="col-md-2 btn btn-primary mt-3 m-2"
            role="button"
          >
            Back to Invoices List
          </Link>
        </div>
        
      </div>
      <ScrollButton />
      <div className="mainWrapper">
        <div className="wrapper">
          <div className="logo"></div>
          <div className="fv">Faktura VAT</div>
          <div className="date">
            <p className="date__paragraph">FVS {invoiceNumber}</p>
            <p className="date__paragraph--small">numer faktury</p>
            <p className="date__paragraph">{invoiceDate}</p>
            <p className="date__paragraph--small">data sprzedaży</p>
            <p className="date__paragraph">{invoiceDate}</p>
            <p className="date__paragraph--small">data wystawienia</p>
          </div>
        </div>
        <div className="wrapper">
          <div className="who">
            <div className="who__header">SPRZEDAWCA:</div>
            <p className="who__paragraph">
              MULTI TRAFFIC SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ
            </p>
            <p className="who__paragraph">ADAMA MICKIEWICZA 37 / 58</p>
            <p className="who__paragraph">01-625 WARSZAWA</p>
            <p className="who__paragraph">NIP: PL 525-27-87-031</p>
            <br />
            <p className="who__paragraph">e-mail: biuro@multitraffic.pl</p>
          </div>
          <div className="who">
            <p className="who__header">NABYWCA:</p>
            <p className="who__paragraph">DRUKARNIA "PERFEKT" SPÓŁKA AKCYJNA</p>
            <p className="who__paragraph">UL. POŁCZYŃSKA 99</p>
            <p className="who__paragraph">01-303 WARSZAWA</p>
            <p className="who__paragraph">NIP: PL 522-250-76-82</p>
          </div>
        </div>
        <div className="payments">
          <div className="box__payments--1">
            <p className="payment__paragraph">
              SPOSÓB PŁATNOŚCI: <b>PRZELEW BANKOWY</b>
            </p>
            <p className="payment__paragraph">
              TERMIN PŁATNOŚCI: <b>{paymentDate}</b>
            </p>
          </div>
          <div className="box__payments--2">
            <p className="payment__paragraph">
              BANK: <b>ING Bank Śląski</b>
            </p>
            <p className="payment__paragraph">
              NUMER KONTA: <b>68 1050 1025 1000 0090 8059 8478</b>
            </p>
          </div>
        </div>
        <table className="invoiceTable">
          <tbody>
            <tr className="row__th">
              <th>lp</th>
              <th>NAZWA</th>
              <th>ILOŚĆ</th>
              <th>jm</th>
              <th>
                Cena
                <br />
                netto
              </th>
              <th>
                Wartość
                <br />
                netto
              </th>
              <th>VAT</th>
              <th>
                Kwota
                <br />
                VAT
              </th>
              <th>
                Wartość
                <br />
                brutto
              </th>
            </tr>
            <tr>
              <td>1</td>
              <td>Prace introligatorskie i konfekcjonowanie</td>
              <td>1</td>
              <td>szt.</td>
              <td>{invoiceTotalErnNetto.toFixed(2)}</td>
              <td>{invoiceTotalErnNetto.toFixed(2)}</td>
              <td>23%</td>
              <td>{invoiceTotalErnVat.toFixed(2)}</td>
              <td>{invoiceTotalErnBrutto.toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan="4" className="cells__empty"></td>

              <td>
                <b>RAZEM PLN:</b>
              </td>
              <td>
                <b>{invoiceTotalErnNetto.toFixed(2)}</b>
              </td>
              <td>
                <b>X</b>
              </td>
              <td>
                <b>{invoiceTotalErnVat.toFixed(2)}</b>
              </td>
              <td>
                <b>{invoiceTotalErnBrutto.toFixed(2)}</b>
              </td>
            </tr>
            <tr>
              <td colSpan="4" className="cells__empty"></td>
              <td>W tym:</td>
              <td>{invoiceTotalErnNetto.toFixed(2)}</td>
              <td>23%</td>
              <td>{invoiceTotalErnVat.toFixed(2)}</td>
              <td>{invoiceTotalErnBrutto.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <div className="wrapper__totalPayment">
          <div className="totalPayment">
            <p className="totalPayment__paragraph">ZAPŁACONO: 0.00 PLN</p>
            <p className="totalPayment__paragraph">
              <b>DO ZAPŁATY: {invoiceTotalErnBrutto.toFixed(2)} PLN</b>
            </p>
            <NumberInWords />
          </div>
        </div>
        <div className="signatures">
          <div className="signatur">
            <div className="who__header">Wystawił(a):</div>

            <p className="who__paragraph--sign">Radosław Bubienko</p>
          </div>
          <div className="signatur">
            <p className="who__header">Odebrał(a):</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
