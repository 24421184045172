import styled from "styled-components";

export const MainWrapper = styled.div`

    height: auto;
    margin: 20px auto;
    padding: 0 16px;
    display: grid;
    grid-template-columns: 1fr;
    
    justify-items: center;
    align-items: center;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
        padding: 0 8px;
        margin: 10px auto;
    }
`;