import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledMainTitle = styled.h1`
  margin: 20px auto;
  font-size: 36px;
  font-weight: 600;
  color: white;
  background: linear-gradient(
    47deg,
    rgba(255, 255, 255, 1) 11%,
    rgba(83, 185, 255, 1) 30%,
    rgba(255, 255, 255, 1) 52%,
    rgba(75, 180, 252, 1) 76%,
    rgba(255, 255, 255, 1) 94%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    margin: 5px auto;
    font-size: 24px;
  }
`;

export const StyledSection = styled.section`
  max-width: 1000px;
`;

export const StyledForm = styled.form`
  margin: 0 auto;
  max-width: 450px;
  border: 3px solid ${({ theme }) => theme.colors.blue};
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 0px 20px black;
`;

export const TableWrapper = styled.div`
  margin: 5px auto;
  max-width: 900px;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle !important;

  @media (max-width: 767px) {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px;
    padding: 2px;
  }
`;

export const TdUpdateDay = styled.td`
  color: white;
  font-size: 22px;
  text-align: left;
  padding-left: 15px;
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const LinkBackTo = styled(Link)`
  text-decoration: none;
  margin: 8px auto 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    width: 100%;
  }

  @media print {
    display: none;
  }
`;

export const LinkShow = styled(Link)`
  text-decoration: none;
  color: lightgreen;

  &:hover {
    border-bottom: 1px solid;
    color: lightgreen;
  }
`;

export const LinkDelete = styled(Link)`
  text-decoration: none;
  color: red;

  &:hover {
    border-bottom: 1px solid;
    color: red;
  }
`;
