import { Link } from "react-router-dom";
import { MainWrapper } from "../MainWrapper";

const Error = () => {
  return (
    <>
    <MainWrapper>
      <section className="section">
        <h1 className="text-primary">404</h1>
        <p className="text-primary">page not foung</p>
        <Link to="/panel" className="btn btn-primary m-2">back home</Link>
      </section>
    </MainWrapper>
    </>
  );
};

export default Error;
