import React, { useState, useEffect } from 'react';
import { BiArrowToTop } from 'react-icons/bi';

function ScrollButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const scrollThreshold = 10;
    
    if (scrollPosition > scrollThreshold) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <div className='d-print-none'>
        <button onClick={scrollToTop} className={`scroll-button ${isVisible ? "visible btn btn-primary" : ""}`}>
          <BiArrowToTop style={{ fontSize: "30px"}}/>
        </button>
      </div>
    </>
  );
}

export default ScrollButton;