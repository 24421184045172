import ReactDOM from "react-dom/client"
import App from "./App"
import { Normalize } from "styled-normalize"
import Modal from "react-modal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Normalize />
      <App />
    </QueryClientProvider>
  </>
)
