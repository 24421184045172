import { GlobalStyle } from "./GlobalStyle";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Result from "../pages/Result";
import Panel from "../pages/Panel";
import Login from "../pages/Login";
import SharedLayout from "../pages/SharedLayout";
import Error from "../common/ErrorPage";
import { useState } from "react";
import ProtectedRoute from "../pages/ProtectedRoute";
import InsDay from "../pages/InsDay";
import getUser from "./getUserFromLocalStorage";
import StatmentList from "../pages/StatmentList";
import Statment from "../pages/StatmentList/Statment";
import Invoice from "../pages/Invoice/invoice.jsx"
import AllDays from "../pages/AllDays";
import EditDay from "../pages/EditDay";
import InvoicesList from "../pages/InvoicesList";

function App() {
  const [user, setUser] = useState(getUser());
  const [invoiceTotalErnNetto, setInvoiceTotalErnNetto] = useState(0);
  

  const handleUpdateInvoiceTotalErnNetto = (invoiceTotalErnNetto) => {
    setInvoiceTotalErnNetto(invoiceTotalErnNetto);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SharedLayout />}>
              <Route index element={<Login setUser={setUser} />} />
              <Route
                path="panel"
                element={
                  <ProtectedRoute user={user}>
                    <Panel user={user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="insday"
                element={
                  <ProtectedRoute user={user}>
                    <InsDay user={user} />
                  </ProtectedRoute>
                }
              />
                            
              <Route
                path="result"
                element={
                  <ProtectedRoute user={user}>
                    <Result user={user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="statments"
                element={
                  <ProtectedRoute user={user}>
                    <StatmentList user={user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="statment/:id"
                element={
                  <ProtectedRoute user={user}>
                    <Statment user={user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="alldays"
                element={
                  <ProtectedRoute user={user}>
                    <AllDays user={user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="editday/:id"
                element={
                  <ProtectedRoute user={user}>
                    <EditDay user={user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="invoiceslist"
                element={
                  <ProtectedRoute user={user}>
                    <InvoicesList user={user} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="invoice/:id"
                element={
                  <ProtectedRoute user={user}>
                    <Invoice
                      user={user}
                      totalErn={invoiceTotalErnNetto}
                      onUpdateInvoiceTotalErnNetto={
                        handleUpdateInvoiceTotalErnNetto
                      }
                    />
                  </ProtectedRoute>
                }
              />

              <Route path="*" element={<Error />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );}

export default App;
