const toLogin = "/";
const toPanel = "/panel";
const toRegister = "/register";
const toResult = "/result";
const toInsDay = "/insday";
const toStatment ="/statment/:id";
const toStatmentsList = "/statments";
const toLastInvoice = "/invoice";
const toAllDays = "/alldays";
const toEditDay = "/editday/:id";
const toInvoicesList = "/invoiceslist"

export {
  toLogin,
  toPanel,
  toRegister,
  toResult,
  toInsDay,
  toStatment,
  toStatmentsList,
  toLastInvoice,
  toAllDays,
  toEditDay,
  toInvoicesList
};