import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../common/Header";
import ScrollButton from "../../scrollButton";
import { MainWrapper } from "../../../common/MainWrapper";
import TodayDate from "../../../common/todayDate";
import moment from "moment/moment";
import {
  LinkBackTo,
  TableWrapper,
  ErrorMessage,
  StyledMainTitle,
} from "../../styled";
import {
  StyledParagraph,
  StyledParagraphAdjustment,
} from "../../../common/LastDay/styled";
import { BackToPanel } from "../../../common/BackToPanel";
import { useResultStatment } from "../../../common/useResultStatment";
import MailSender from "../../../common/MailSender";
import CreateInvoiceButton from "../../../common/createInvoiceButton";
import { baseUrl } from "../../../common/apiUrl";

const Statment = () => {
  const { id } = useParams();
  const [datesFromPHP, setDatesFromPHP] = useState({
    date_from: "",
    date_to: "",
    bonus_percentage: 0.0,
    extra_amount: 0.0,
  });

  const item = useResultStatment({ datesFromPHP });

  useEffect(() => {
    const fetchDatesPHP = async () => {
      try {
        const response = await axios.post(`${baseUrl}/statment.php?id=` + id);
        const datesFromPHP = response.data;

        setDatesFromPHP({
          date_from: datesFromPHP.date_from,
          date_to: datesFromPHP.date_to,
          bonus_percentage: datesFromPHP.bonus_percentage,
          extra_amount: datesFromPHP.extra_amount,
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchDatesPHP();
  }, [id]);

  let date_from = datesFromPHP.date_from;
  let date_to = datesFromPHP.date_to;
  let bonus_percentage = datesFromPHP.bonus_percentage;
  let extra_amount = datesFromPHP.extra_amount;
  let bonusAmount = 0;
  let totalErnWithBonus = 0;

  let totalRegularHours = 0;
  let regularHoursRate = 0;
  let erningFromRegularHours = 0;

  let totalRegularHours24072023 = 0;
  let regularHoursRate24072023 = 0;
  let erningFromRegularHours24072023 = 0;

  let totalOperatorHours = 0;
  let operatorHoursRate = 0;
  let erningFromOperatorHours = 0;

  let totalOperatorHours24072023 = 0;
  let operatorHoursRate24072023 = 0;
  let erningFromOperatorHours24072023 = 0;

  let totalOperatorHoursWeekend23102022 = 0;
  let operatorHoursWeekend23102022Rate = 0;
  let erningFromOperatorHoursWeekend23102022 = 0;

  let totalOperatorHoursWeekend24072023 = 0;
  let operatorHoursWeekendRate24072023 = 0;
  let erningFromOperatorHoursWeekend24072023 = 0;

  let totalOperatorHoursWeekend = 0;
  let operatorHoursWeekendRate = 0;
  let erningFromOperatorHoursWeekend = 0;

  let totalRegularHoursWeekend = 0;
  let regularHoursWeekendRate = 0;
  let erningFromRegularHoursWeekend = 0;

  let totalOperatorHoursHoliday = 0;
  let operatorHoursHolidayRate = 0;
  let erningFromOperatorHoursHoliday = 0;

  let totalRegularHoursHoliday = 0;
  let regularHoursHolidayRate = 0;
  let erningFromRegularHoursHoliday = 0;

  let totalRegularHoursCorrection = 0;
  let regularHoursCorrectionRate = 0;
  let erningFromRegularHoursCorrection = 0;

  let totalOperatorHoursCorrection = 0;
  let operatorHoursCorrectionRate = 0;
  let erningFromOperatorHoursCorrection = 0;

  let totalHours = 0;
  let totalErn = 0;

  let FormattedData1 = moment(date_from).locale("en-gb").format("YYYY-MM-DD");
  let FormattedData2 = moment(date_to).locale("en-gb").format("YYYY-MM-DD");

  return (
    <>
      <Header />
      <ScrollButton />
      <MainWrapper>
        <StyledMainTitle>Statment </StyledMainTitle>

        <BackToPanel />
        {datesFromPHP.date_from && datesFromPHP.date_to ? (
          <>
            <p className="onlyPrint__header">
              WTR - Working Time Records
              <br />
              <small>Radek Bubienko</small>
            </p>
            <p className="statment__from">
              Statement from {date_from} to {date_to}
            </p>
            <TableWrapper>
              <table className="table table-dark table-striped table-bordered table-modified">
                <thead>
                  <tr>
                    <td>Date</td>
                    <td>Shift</td>
                    <td>
                      Hours
                      <br />
                      worked
                    </td>
                    <td>
                      Rate
                      <br />
                      per hour
                    </td>
                    <td>
                      Daily
                      <br />
                      earnings
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {item.map((item) => {
                    let FormattedDataPracy = moment(item.data_pracy)
                      .locale("en-gb")
                      .format("YYYY-MM-DD - dddd");

                    let parseIlGodz = parseFloat(item.il_godzin);

                    if (
                      item.typ_stawki === "regular" &&
                      item.data_od === "2022-07-01"
                    ) {
                      totalRegularHours += parseIlGodz;
                      regularHoursRate = item.stawka;
                      erningFromRegularHours =
                        totalRegularHours * regularHoursRate;
                    }

                    if (
                      item.typ_stawki === "regular" &&
                      item.data_od === "2023-07-24"
                    ) {
                      totalRegularHours24072023 += parseIlGodz;
                      regularHoursRate24072023 = item.stawka;
                      erningFromRegularHours24072023 =
                        totalRegularHours24072023 * regularHoursRate24072023;
                    }

                    if (
                      item.typ_stawki === "operator" &&
                      item.data_od === "2024-01-01"
                    ) {
                      totalOperatorHours += parseIlGodz;
                      operatorHoursRate = item.stawka;
                      erningFromOperatorHours =
                        totalOperatorHours * operatorHoursRate;
                    }

                    if (
                      item.typ_stawki === "operator" &&
                      item.data_od === "2023-07-24"
                    ) {
                      totalOperatorHours24072023 += parseIlGodz;
                      operatorHoursRate24072023 = item.stawka;
                      erningFromOperatorHours24072023 =
                        totalOperatorHours24072023 * operatorHoursRate24072023;
                    }

                    if (
                      item.typ_stawki === "operator weekend" &&
                      item.data_od === "2022-07-01"
                    ) {
                      totalOperatorHoursWeekend23102022 += parseIlGodz;
                      operatorHoursWeekend23102022Rate = item.stawka;
                      erningFromOperatorHoursWeekend23102022 =
                        totalOperatorHoursWeekend23102022 *
                        operatorHoursWeekend23102022Rate;
                    }

                    if (
                      item.typ_stawki === "operator weekend" &&
                      item.data_od === "2024-01-01"
                    ) {
                      totalOperatorHoursWeekend += parseIlGodz;
                      operatorHoursWeekendRate = item.stawka;
                      erningFromOperatorHoursWeekend =
                        totalOperatorHoursWeekend * operatorHoursWeekendRate;
                    }

                    if (
                      item.typ_stawki === "operator weekend" &&
                      item.data_od === "2023-07-24"
                    ) {
                      totalOperatorHoursWeekend24072023 += parseIlGodz;
                      operatorHoursWeekendRate24072023 = item.stawka;
                      erningFromOperatorHoursWeekend24072023 =
                        totalOperatorHoursWeekend24072023 *
                        operatorHoursWeekendRate24072023;
                    }

                    if (item.typ_stawki === "regular weekend") {
                      totalRegularHoursWeekend += parseIlGodz;
                      regularHoursWeekendRate = item.stawka;
                      erningFromRegularHoursWeekend =
                        totalRegularHoursWeekend * regularHoursWeekendRate;
                    }

                    if (item.typ_stawki === "operator holiday") {
                      totalOperatorHoursHoliday += parseIlGodz;
                      operatorHoursHolidayRate = item.stawka;
                      erningFromOperatorHoursHoliday =
                        totalOperatorHoursHoliday * operatorHoursHolidayRate;
                    }

                    if (item.typ_stawki === "regular holiday") {
                      totalRegularHoursHoliday += parseIlGodz;
                      regularHoursHolidayRate = item.stawka;
                      erningFromRegularHoursHoliday =
                        totalRegularHoursHoliday * regularHoursHolidayRate;
                    }

                    if (item.typ_stawki === "regular correction") {
                      totalRegularHoursCorrection += parseIlGodz;
                      regularHoursCorrectionRate = item.stawka;
                      erningFromRegularHoursCorrection =
                        totalRegularHoursCorrection *
                        regularHoursCorrectionRate;
                    }

                    if (item.typ_stawki === "operator correction") {
                      totalOperatorHoursCorrection += parseIlGodz;
                      operatorHoursCorrectionRate = item.stawka;
                      erningFromOperatorHoursCorrection =
                        totalOperatorHoursCorrection *
                        operatorHoursCorrectionRate;
                    }

                    totalHours =
                      totalRegularHours +
                      totalRegularHours24072023 +
                      totalOperatorHours +
                      totalOperatorHours24072023 +
                      totalOperatorHoursWeekend23102022 +
                      totalOperatorHoursWeekend24072023 +
                      totalOperatorHoursWeekend +
                      totalRegularHoursWeekend +
                      totalOperatorHoursHoliday +
                      totalRegularHoursHoliday -
                      totalRegularHoursCorrection -
                      totalOperatorHoursCorrection;

                    totalErn =
                      erningFromRegularHours +
                      erningFromRegularHours24072023 +
                      erningFromOperatorHours +
                      erningFromOperatorHours24072023 +
                      erningFromOperatorHoursWeekend23102022 +
                      erningFromOperatorHoursWeekend +
                      erningFromOperatorHoursWeekend24072023 +
                      erningFromRegularHoursWeekend +
                      erningFromOperatorHoursHoliday +
                      erningFromRegularHoursHoliday -
                      erningFromRegularHoursCorrection -
                      erningFromOperatorHoursCorrection;

                    bonusAmount = (totalErn * bonus_percentage) / 100;
                    totalErnWithBonus = bonusAmount + totalErn + +extra_amount;

                    console.log(typeof extra_amount);

                    if (bonus_percentage === null && extra_amount === null) {
                      window.localStorage.setItem(
                        "totalErnNetto",
                        totalErn.toFixed(2)
                      );
                    } else {
                      window.localStorage.setItem(
                        "totalErnNetto",
                        totalErnWithBonus.toFixed(2)
                      );
                    }

                    return (
                      <tr key={item.id_daty}>
                        <td>{FormattedDataPracy}</td>
                        <td>{item.zmiana}</td>
                        <td>{item.il_godzin}</td>
                        <td>
                          {item.typ_stawki} - {item.stawka} PLN
                        </td>
                        <td>{item.il_godzin * item.stawka} PLN</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableWrapper>

            <LinkBackTo to="/statments">
              <button className="btn btn-primary btn-lg ps-3 pe-3">
                Back to Statments List
              </button>
            </LinkBackTo>

            <StyledParagraph className="mb-2">
              Please verify the hours for the period:
              <br /> from {FormattedData1} to {FormattedData2}
            </StyledParagraph>
            {totalRegularHours > 0 ? (
              <StyledParagraph>
                Number of regular hours:
                <br /> {totalRegularHours.toFixed(1)} h x{regularHoursRate} PLN
                = {erningFromRegularHours.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalRegularHours24072023 > 0 ? (
              <StyledParagraph>
                Number of regular hours:
                <br /> {totalRegularHours24072023.toFixed(1)} h x
                {regularHoursRate24072023} PLN =
                {erningFromRegularHours24072023.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHours > 0 ? (
              <StyledParagraph>
                Number of operator hours:
                <br />
                {totalOperatorHours.toFixed(1)}&nbsp;h x&nbsp;
                {operatorHoursRate} PLN = {erningFromOperatorHours.toFixed(2)}{" "}
                PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHours24072023 > 0 ? (
              <StyledParagraph>
                Number of operator hours till 2023-12-31:
                <br />
                {totalOperatorHours24072023.toFixed(1)} h x&nbsp;
                {operatorHoursRate24072023} PLN ={" "}
                {erningFromOperatorHours24072023.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHoursWeekend23102022 > 0 ? (
              <StyledParagraph>
                Number of weekend operator hours till 23.10.2022 :<br />
                &nbsp;
                {totalOperatorHoursWeekend23102022.toFixed(1)} h x&nbsp;
                {operatorHoursWeekend23102022Rate} PLN =&nbsp;
                {erningFromOperatorHoursWeekend23102022.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHoursWeekend > 0 ? (
              <StyledParagraph>
                Number of weekend operator hours:
                <br />
                {totalOperatorHoursWeekend.toFixed(1)} h x&nbsp;
                {operatorHoursWeekendRate} PLN =&nbsp;
                {erningFromOperatorHoursWeekend.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHoursWeekend24072023 > 0 ? (
              <StyledParagraph>
                Number of weekend operator hours:
                <br />
                {totalOperatorHoursWeekend24072023.toFixed(1)} h x&nbsp;
                {operatorHoursWeekendRate24072023} PLN =&nbsp;
                {erningFromOperatorHoursWeekend24072023.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalRegularHoursWeekend > 0 ? (
              <StyledParagraph>
                Number of weekend regular hours:
                <br />
                {totalRegularHoursWeekend.toFixed(1)} h x{" "}
                {regularHoursWeekendRate}
                &nbsp; PLN = {erningFromRegularHoursWeekend.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalRegularHoursHoliday > 0 ? (
              <StyledParagraph>
                Number of operator hours on a holiday:
                <br />
                {totalRegularHoursHoliday.toFixed(1)} h x&nbsp;
                {regularHoursHolidayRate} PLN =&nbsp;
                {erningFromRegularHoursHoliday.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalOperatorHoursHoliday > 0 ? (
              <StyledParagraph>
                Number of operator hours on a holiday:
                <br />
                {totalOperatorHoursHoliday.toFixed(1)} h x&nbsp;
                {operatorHoursHolidayRate} PLN =&nbsp;
                {erningFromOperatorHoursHoliday.toFixed(2)} PLN.
              </StyledParagraph>
            ) : null}
            {totalRegularHoursCorrection > 0 ? (
              <StyledParagraphAdjustment>
                Number of regular hours - correction:
                <br />
                {totalRegularHoursCorrection.toFixed(1)} h x&nbsp;
                {regularHoursCorrectionRate} PLN =&nbsp;
                {erningFromRegularHoursCorrection.toFixed(2)} PLN.
              </StyledParagraphAdjustment>
            ) : null}
            {totalOperatorHoursCorrection > 0 ? (
              <StyledParagraphAdjustment>
                Number of operator hours - correction:
                <br />
                {totalOperatorHoursCorrection.toFixed(1)} h x&nbsp;
                {operatorHoursCorrectionRate} PLN =&nbsp;
                {erningFromOperatorHoursCorrection.toFixed(2)} PLN.
              </StyledParagraphAdjustment>
            ) : null}
            {totalHours > 0 ? (
              <StyledParagraph>
                <br />
                Total number of hours: {totalHours.toFixed(1)} h.
              </StyledParagraph>
            ) : null}
            {totalErn > 0 ? (
              <StyledParagraph>
                Total to pay: {totalErn.toFixed(2)} PLN/netto.
              </StyledParagraph>
            ) : null}
            {extra_amount > 0 ? (
              <StyledParagraph>
                Extra amount: {extra_amount} PLN/netto.
              </StyledParagraph>
            ) : null}
            {bonus_percentage > 0 ? (
              <StyledParagraph>
                Bonus {bonus_percentage}% = {bonusAmount.toFixed(2)} PLN/netto.
              </StyledParagraph>
            ) : null}
            {bonus_percentage > 0 || extra_amount > 0 ? (
              <StyledParagraph>
                Total to pay with bonuses: {totalErnWithBonus.toFixed(2)}{" "}
                PLN/netto.
              </StyledParagraph>
            ) : null}
          </>
        ) : (
          <ErrorMessage>Coś poszło nie tak! Spróbój jeszcze raz.</ErrorMessage>
        )}

        {totalErn > 0 ? (
          <>
            <div className="container-fluid d-print-none">
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-lg mt-3 m-2 col-md-2"
                  onClick={() => window.print()}
                >
                  Print
                </button>
                <MailSender
                  date_from={date_from}
                  date_to={date_to}
                  totalRegularHours={totalRegularHours}
                  regularHoursRate={regularHoursRate}
                  erningFromRegularHours={erningFromRegularHours}
                  totalRegularHours24072023={totalRegularHours24072023}
                  regularHoursRate24072023={regularHoursRate24072023}
                  erningFromRegularHours24072023={
                    erningFromRegularHours24072023
                  }
                  totalOperatorHours={totalOperatorHours}
                  operatorHoursRate={operatorHoursRate}
                  erningFromOperatorHours={erningFromOperatorHours}
                  totalOperatorHours24072023={totalOperatorHours24072023}
                  operatorHoursRate24072023={operatorHoursRate24072023}
                  erningFromOperatorHours24072023={
                    erningFromOperatorHours24072023
                  }
                  totalOperatorHoursWeekend23102022={
                    totalOperatorHoursWeekend23102022
                  }
                  operatorHoursWeekend23102022Rate={
                    operatorHoursWeekend23102022Rate
                  }
                  erningFromOperatorHoursWeekend23102022={
                    erningFromOperatorHoursWeekend23102022
                  }
                  totalOperatorHoursWeekend24072023={
                    totalOperatorHoursWeekend24072023
                  }
                  operatorHoursWeekendRate24072023={
                    operatorHoursWeekendRate24072023
                  }
                  erningFromOperatorHoursWeekend24072023={
                    erningFromOperatorHoursWeekend24072023
                  }
                  totalOperatorHoursWeekend={totalOperatorHoursWeekend}
                  operatorHoursWeekendRate={operatorHoursWeekendRate}
                  erningFromOperatorHoursWeekend={
                    erningFromOperatorHoursWeekend
                  }
                  totalRegularHoursWeekend={totalRegularHoursWeekend}
                  regularHoursWeekendRate={regularHoursWeekendRate}
                  erningFromRegularHoursWeekend={erningFromRegularHoursWeekend}
                  totalRegularHoursHoliday={totalRegularHoursHoliday}
                  regularHoursHolidayRate={regularHoursHolidayRate}
                  erningFromRegularHoursHoliday={erningFromRegularHoursHoliday}
                  totalOperatorHoursHoliday={totalOperatorHoursHoliday}
                  operatorHoursHolidayRate={operatorHoursHolidayRate}
                  erningFromOperatorHoursHoliday={
                    erningFromOperatorHoursHoliday
                  }
                  totalRegularHoursCorrection={totalRegularHoursCorrection}
                  regularHoursCorrectionRate={regularHoursCorrectionRate}
                  erningFromRegularHoursCorrection={
                    erningFromRegularHoursCorrection
                  }
                  totalOperatorHoursCorrection={totalOperatorHoursCorrection}
                  operatorHoursCorrectionRate={operatorHoursCorrectionRate}
                  erningFromOperatorHoursCorrection={
                    erningFromOperatorHoursCorrection
                  }
                  totalErn={totalErn}
                  totalHours={totalHours}
                  bonus_percentage={bonus_percentage}
                  bonusAmount={bonusAmount}
                  totalErnWithBonus={totalErnWithBonus}
                  extra_amount={extra_amount}
                />

                <CreateInvoiceButton />
              </div>
            </div>
          </>
        ) : null}

        <div className="text-white mt-3">
          <TodayDate />
        </div>
      </MainWrapper>
    </>
  );
};

export default Statment;
