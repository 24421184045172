import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "./apiUrl";

export const useAllInvoices = () => {
  const [item, setItem] = useState([]);

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getAllInvoices.php`);

        setItem(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchResult();
  }, []);

  return item;
};
