import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "./apiUrl";

export const useResultStatment = ({ datesFromPHP }) => {
  const [item, setItem] = useState([]);

  const data1 = datesFromPHP.date_from;
  const data2 = datesFromPHP.date_to;
  

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/result.php`,
          { data1, data2 }
        );

        setItem(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchResult();
  }, [data1, data2]);

  return item;
};
