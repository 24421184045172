import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainWrapper } from "../../common/MainWrapper";
import Header from "../../common/Header";
import LastDay from "../../common/LastDay";
import { StyledTable, StyledMainTitle } from "../styled";
import { BackToPanel } from "../../common/BackToPanel";
import { baseUrl } from "../../common/apiUrl";

const InsDay = () => {
  const navigate = useNavigate();
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [data, setData] = useState({
    data_pracy: "",
    zmiana: "",
    il_godzin: "",
    id_stawka: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    setData({...data, data_pracy: "", zmiana: "", il_godzin: "", id_stawka: ""});
  }

  const validateForm = (data) => {
    if(!data.data_pracy) {
      return "Date is required!";
    }
    if (!data.zmiana) {
      return "Shift is required";
    }
    if (!data.il_godzin) {
      return "Hours is required";
    }
    if (!data.id_stawka) {
      return "Rate is required";
    }
    return null;
  };

  const submitForm = (e) => {
    e.preventDefault();
    
    const errorMsg = validateForm(data);
    if(errorMsg) {
      setError(errorMsg);
      return
    };
    const sendData = {
      data_pracy: data.data_pracy,
      zmiana: data.zmiana,
      il_godzin: data.il_godzin,
      id_stawka: data.id_stawka,
    };

    axios
      .post(`${baseUrl}/insday.php`, sendData)
      .then((result) => {
        if (result.data.Status === "Invalid") {
          alert("Invalid Data");
        } else {
          setIsSubmitForm(true);
          setIsLoading(true);
          setError(null);
          navigate("/insday");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
          setIsSubmitForm(false);
          handleReset()
        }, 1000);
      });
  };

  
  const tdStyled = { width: "60%"};

  return (
    <>
      <Header />
      <MainWrapper>
        {isLoading && (
          <>
            <h5 className="text-danger">Adds a new working day...</h5>
          </>
        )}
        {!isLoading && null}
        <StyledMainTitle>Insert Day of Work</StyledMainTitle>

        <div className="container-fluid">
          {error && (
            <div className="text-danger col-md-12 ms-2 mb-3">{error}</div>
          )}
          <form onSubmit={submitForm} noValidate>
            <StyledTable>
              <tbody>
                <tr>
                  <td className="float-end me-2 text-primary p-2">Date: </td>
                  <td style={tdStyled}>
                    {" "}
                    <input
                      type="date"
                      className="form-control m-1"
                      name="data_pracy"
                      id="data_pracy"
                      onChange={handleChange}
                      value={data.data_pracy}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="float-end me-1 text-primary p-2">
                    Select shift:{" "}
                  </td>
                  <td>
                    <select
                      name="zmiana"
                      id="zmiana"
                      className="form-control m-1"
                      onChange={handleChange}
                      value={data.zmiana}
                    >
                      <option defaultValue="">select shift</option>
                      <option value="1 shift">1 shift</option>
                      <option value="2 shift">2 shift</option>
                      <option value="3 shift">3 shift</option>
                      <option value="6-18">6-18</option>
                      <option value="18-6">18-6</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="float-end me-1 text-primary p-1">
                    Select number of hours: 
                  </td>
                  <td>
                    <select
                      name="il_godzin"
                      id="il_godzin"
                      className="form-control m-1"
                      onChange={handleChange}
                      value={data.il_godzin}
                    >
                      <option defaultValue="">hours</option>
                      <option value="8.0">8.0</option>
                      <option value="12.0">12.0</option>
                      <option value="7.0">7.0</option>
                      <option value="7.5">7.5</option>
                      <option value="8.5">8.5</option>
                      <option value="9.0">9.0</option>
                      <option value="9.5">9.5</option>
                      <option value="10.0">10.0</option>
                      <option value="10.5">10.5</option>
                      <option value="11.0">11.0</option>
                      <option value="11.5">11.5</option>
                      <option value="12.5">12.5</option>
                      <option value="1.0">1.0</option>
                      <option value="1.5">1.5</option>
                      <option value="2.0">2.0</option>
                      <option value="2.5">2.5</option>
                      <option value="3.0">3.0</option>
                      <option value="3.5">3.5</option>
                      <option value="4.0">4.0</option>
                      <option value="4.5">4.5</option>
                      <option value="5.0">5.0</option>
                      <option value="5.5">5.5</option>
                      <option value="6.0">6.0</option>
                      <option value="6.5">6.5</option>
                      <option value="13.0">13.0</option>
                      <option value="13.5">13.5</option>
                      <option value="14.0">14.0</option>
                      <option value="14.5">14.5</option>
                      <option value="15.0">15.0</option>
                      <option value="15.5">15.5</option>
                      <option value="16.0">16.0</option>
                      <option value="16.5">16.5</option>
                      <option value="17.0">17.0</option>
                      <option value="17.5">17.5</option>
                      <option value="18.0">18.0</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="float-end me-1 text-primary p-2">
                    Select rate (PLN):{" "}
                  </td>
                  <td>
                    <select
                      name="id_stawka"
                      id="id_stawka"
                      className="form-control m-1"
                      onChange={handleChange}
                      value={data.id_stawka}
                    >
                      <option defaultValue="">rates</option>
                      <option value="11">regular 33 PLN/h</option>
                      <option value="16">operator 44 PLN/h</option>
                      <option value="17">operator weekend 58 PLN/h</option>
                      <option value="14">correction - regular -33 PLN/h</option>
                      <option value="18">correction - operator -44  PLN/h</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </StyledTable>

            <div>
              <button type="submit" className="btn btn-primary btn-lg m-2">
                Save
              </button>

              <button
                type="reset"
                className="btn btn-primary btn-lg m-2"
                onClick={handleReset}
              >
                Clear
              </button>
            </div>
          </form>
        </div>
        <BackToPanel />

        {!isSubmitForm && <LastDay />}
        {isSubmitForm && <LastDay />}
      </MainWrapper>
    </>
  );
};

export default InsDay;
