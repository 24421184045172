import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import { baseUrl } from "./apiUrl";

const CreateInvoiceButton = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [bonusAmount, setBonusAmount] = useState(0.0);

  const [errors, setErrors] = useState({
    invoiceDate: "",
    paymentDate: "",
    invoiceNumber: "",
  });

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      const totalErnNetto = window.localStorage.getItem("totalErnNetto");
      const sendData = {
        invoiceDate: invoiceDate,
        paymentDate: paymentDate,
        invoiceNumber: invoiceNumber,
        totalErnNetto: +totalErnNetto,
        bonusAmount: bonusAmount,
      };

      axios
        .post(`${baseUrl}/insInvoice.php`, sendData)
        .then((result) => {
          if (result.data.Status === "Invalid") {
            alert("Invalid Data");
          } else {
            navigate("/invoiceslist");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      invoiceDate: "",
      paymentDate: "",
      invoiceNumber: "",
    };

    if (!invoiceDate) {
      newErrors.invoiceDate = "Wprowadź datę wystawienia faktury";
      isValid = false;
    }

    if (!paymentDate) {
      newErrors.paymentDate = "Wprowadź datę płatności";
      isValid = false;
    }

    if (!invoiceNumber) {
      newErrors.invoiceNumber = "Wprowadź numer faktury";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const closeModal = () => {
    setShowModal(false);
    setErrors({
      invoiceDate: "",
      paymentDate: "",
      invoiceNumber: "",
    });
  };

  const modalStyles = {
    content: {
      width: "290px",
      height: "445px",
      margin: "auto",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.7)",
      padding: "15px",
    },
  };

  return (
    <>
      <button
        className="btn btn-primary btn-lg mt-3 m-2 col-md-2"
        onClick={handleButtonClick}
      >
        Invoice
      </button>
      <Modal isOpen={showModal} onRequestClose={closeModal} style={modalStyles}>
        <form onSubmit={handleSubmit}>
          <div>
            <label for="dateIssueInvoice" className="form-label mt-2 mb-1 text-primary">
              Date of invoice:
            </label>
            <input
              id="dateIssueInvoice"
              type="date"
              value={invoiceDate}
              onChange={(e) => setInvoiceDate(e.target.value)}
              className={`form-control ${errors.invoiceDate ? "error" : ""}`}
            />
            <p className="text-danger">
              <small>{errors.invoiceDate}</small>
            </p>
          </div>

          <div>
            <label for="paymentDate" className="form-label mt-2 mb-1 text-primary">
              Date of payment:
            </label>
            <input
              id="paymentDate"
              type="date"
              value={paymentDate}
              onChange={(e) => setPaymentDate(e.target.value)}
              className={`form-control ${errors.paymentDate ? "error" : ""}`}
            />
            {errors.paymentDate && (
              <p className="text-danger">
                <small>{errors.paymentDate}</small>
              </p>
            )}
          </div>
          <div>
            <label for="invoiceNumber" className="form-label mt-2 mb-1 text-primary">
              Invoice number:
            </label>
            <input
              id="invoiceNumber"
              type="text"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              className={`form-control ${errors.invoiceNumber ? "error" : ""}`}
            />
            {errors.invoiceNumber && (
              <p className="text-danger">
                <small>{errors.invoiceNumber}</small>
              </p>
            )}
          </div>
          <div>
            <label for="bonusAmount" className="form-label mt-2 mb-1 text-primary">
              Bonus amount:
            </label>
            <input
              id="bonusAmount"
              type="number"
              step="0.01"
              inputMode="decimal"
              value={bonusAmount}
              onChange={(e) => setBonusAmount(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="row justify-content-center">
            <button type="submit" className="btn btn-primary m-3 col-md-4">
              OK
            </button>
            <button
              onClick={closeModal}
              className="btn btn-primary m-3 col-md-4"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CreateInvoiceButton;
