import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "./apiUrl";
import { useParams } from "react-router-dom";

function NumberInWords() {

  const { id } = useParams();

  const [invoiceData, setInvoiceData] = useState({
    totalErnNetto: "",
    bonusAmount: "",
  });

  const [dataLoaded, setDataLoaded] = useState(false);
  const [convertedNumber, setConvertedNumber] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getInvoice.php?id=` + id);

        const invoiceData = response.data;

        setInvoiceData({
          totalErnNetto: invoiceData.totalErnNetto,
          bonusAmount: invoiceData.bonusAmount
        });
        setDataLoaded(true);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (dataLoaded) {
  
      const numberInWords = (totalErnBrutto) => {
        const units = [
          "",
          "jeden",
          "dwa",
          "trzy",
          "cztery",
          "pięć",
          "sześć",
          "siedem",
          "osiem",
          "dziewięć",
        ];

        const tens = [
          '',
          'dziesięć',
          'dwadzieścia',
          'trzydzieści',
          'czterdzieści',
          'pięćdziesiąt',
          'sześćdziesiąt',
          'siedemdziesiąt',
          'osiemdziesiąt',
          'dziewięćdziesiąt'
        ]

        const teens = [
          "",
          "jedenaście",
          "dwanaście",
          "trzynaście",
          "czternaście",
          "piętnaście",
          "szesnaście",
          "siedemnaście",
          "osiemnaście",
          "dziewiętnaście",
        ];

        const hundreds = [
          "",
          "sto",
          "dwieście",
          "trzysta",
          "czterysta",
          "pięćset",
          "sześćset",
          "siedemset",
          "osiemset",
          "dziewięćset",
        ];

        const thousands = [
          "",
          "tysiąc",
          "dwa tysiące",
          "trzy tysiące",
          "cztery tysiące",
          "pięć tysięcy",
          "sześć tysięcy",
          "siedem tysięcy",
          "osiem tysięcy",
          "dziewięć tysięcy",
          "dziesięć tysięcy",
          "jedenaście tysięcy",
          "dwanaście tysięcy",
          "trzynaście tysięcy",
          "czternaście tysięcy",
          "piętnaście tysięcy",
          "szesnaście tysięcy",
          "siedemnaście tysięcy",
          "osiemnaście tysięcy",
          "dziewiętnaście tysięcy",
          "dwadzieścia tysięcy",
          "dwadzieścia jeden tysięcy",
          "dwadzieścia dwa tysiące",
          "dwadzieścia trzy tysiące",
          "dwadzieścia cztery tysiące",
          "dwadzieścia pięć tysięcy",
        ];
    
        let wholePart = Math.floor(totalErnBrutto);
        let decimalPart = Math.round((totalErnBrutto - wholePart) * 100);

        let numberInWords = "";

        if (wholePart === 0) {
          numberInWords = "zero";
        } else {
          if (wholePart === 1) {
            numberInWords = "jeden tysiąc ";
          } else {
            const thousand = Math.floor(wholePart / 1000);
            const rest = wholePart % 1000;
      
            if (thousand > 0) {
              numberInWords += `${thousands[thousand]} `;
            }

            if (rest > 0) {
              if (rest < 20) {
                numberInWords += `${thousands[rest]} `;
              } else {
                const houndredsRest = Math.floor(rest / 100);
                const tensRest = Math.floor((rest % 100) / 10);
                const unitsRest = rest % 10;
          
                if (houndredsRest > 0) {
                  numberInWords += `${hundreds[houndredsRest]} `;
                }

                if (tensRest === 1 && unitsRest > 0) {
                  numberInWords += `${teens[unitsRest]} `;
                } else {
                  if (tensRest > 0) {
                    numberInWords += `${tens[tensRest]} `;
                  }
                  if (unitsRest > 0) {
                    numberInWords += `${units[unitsRest]} `;
                  }
                }
              }
            }
          }
      
          numberInWords += getCurrencyWord(wholePart, "złoty", "złote", "złotych");

          if (decimalPart > 0) {
            numberInWords += ` i ${decimalPart}/100 groszy`;
          }
        }

        return numberInWords;
    
      };
      
      const totalErnBrutto = (parseFloat(invoiceData.totalErnNetto) + parseFloat(invoiceData.bonusAmount)) * 1.23;
      const convertedNumber = numberInWords(totalErnBrutto);
      setConvertedNumber(convertedNumber);
    }
  }, [dataLoaded, invoiceData]);

  const getCurrencyWord = (number, singular, pluralFew, pluralMany) => {
    if (number === 1) {
      return singular;
    } else if (
      number % 10 >= 2 &&
      number % 10 <= 4 &&
      (number % 100 < 10 || number % 100 >= 20)
    ) {
      return pluralFew;
    } else {
      return pluralMany;
    }
  };

  return (
    <p>
      <small>(słownie: {convertedNumber})</small>
    </p>
  
  );
  
}

export default NumberInWords;