import { LinkBackTo } from "../pages/styled"
export const BackToListAllDays = () => {
  return (
    <div className="mt-1 mb-3">
      <LinkBackTo to="/alldays">
        <button className="btn btn-outline-primary btn-sm ps-3 pe-3">
          Back to List all Days
        </button>
      </LinkBackTo>
    </div>
  );
}
