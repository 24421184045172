import { StyledParagraph } from "./LastDay/styled";

const TodayDate = () => {
  const showDate = new Date();

  const displayTodayDate = showDate.toLocaleDateString("locale", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    
  });

  const displayActualTime = showDate.toLocaleTimeString("locale", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <StyledParagraph>
      <span>
        Date of issue, {displayTodayDate}, {displayActualTime}
      </span>
    </StyledParagraph>
  );
};

export default TodayDate;
