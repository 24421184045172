import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainWrapper } from "../../common/MainWrapper";
import { StyledForm, StyledMainTitle } from "../styled";
import axios from "axios";
import { baseUrl } from "../../common/apiUrl";

const Login = ({ setUser }) => {
  const navigate = useNavigate();

  const validateForm = user => {
    if(!user.userName) {
      return "User Name is required"
    } else if(user.userName.length < 5) {
      return "User Name is to short. Min. 5 characters."
    }

    if(!user.password) {
      return "Password is required"
    } else if(user.password.length < 5) {
      return "Password is to short. Min. 5 characters."
    }

    return null;    
  };

  const [error, setError] = useState(null);
  const [invUser, setInvUser] = useState(null);
  
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [capsLockOn, setCapsLockOn] = useState(false);

  function handleCapsLock(event) {
    if (event.getModifierState("CapsLock")) {
      setCapsLockOn(true);
    } else {
      setCapsLockOn(false);
    }
  }

  const submitForm = (e) => {
    e.preventDefault();
    const user = { userName, password };
    setUser(user);
    const errorMsg = validateForm(user);
    if(errorMsg) {
      setError(errorMsg);
      return;
    }

    axios
      .post(`${baseUrl}/login.php`, user)
      .then((rs) => {
        if (rs.data.Status === "200") {
          const userName = rs.data.userName;

          localStorage.setItem("user", JSON.stringify(user));

          window.localStorage.setItem("UserName", userName);

          navigate("/panel");
        } else if (rs.status === 202) {
          setInvUser("Invalid User Name or Password.");
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 202) {
          setInvUser("An error occurred: " + error.rs.data.message);
        } else {
          setInvUser("An error ocurred.");
        }
      });
  };

  return (
    <>
      <MainWrapper>
        {invUser && <div className="text-danger m-1">{invUser}</div>}

        <StyledMainTitle>Login</StyledMainTitle>

        <StyledForm onSubmit={submitForm} className="text-center col-md-12">
          <div className="row">
            {error && (
              <div className="text-danger col-md-12 ms-2 mb-3">{error}</div>
            )}
            <div className="text-primary text-start mb-2">User Name:</div>
            <div className="mb-3">
              <input
                type="text"
                name="userName"
                className="form-control"
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
                onKeyUp={handleCapsLock}
              />
            </div>
          </div>

          <div className="text-primary text-start mb-2">Password:</div>
          <div className="mb-2">
            <input
              type="password"
              name="password"
              className="form-control"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              onKeyUp={handleCapsLock}
            />
            {capsLockOn && (
              <div className="caps-lock-text position-absolute text-danger">
                Caps Lock ON!
              </div>
            )}
          </div>

          <div className="mt-4">
            <input
              type="submit"
              name="submit"
              className="btn btn-primary btn-lg ps-5 pe-5"
              value="Login"
            />
          </div>
        </StyledForm>
      </MainWrapper>
    </>
  );
};

export default Login;
