import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../../../src/style.css";
import { MainWrapper } from "../../common/MainWrapper";
import ScrollButton from "../scrollButton";
import { StyledTable, TdUpdateDay } from "../styled";
import Header from "../../common/Header";
import { baseUrl } from "../../common/apiUrl";
import { BackToListAllDays } from "../../common/BackToListAllDays";

const EditDay = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const [dayByID, setDayById] = useState({
    id_daty: "",
    data_pracy: "",
    zmiana: "",
    il_godzin: "",
    id_stawka: "",
    typ_stawki: "",
    stawka: "",
  });

  const handleChange = (e) => {
    setDayById({ ...dayByID, [e.target.name]: e.target.value });
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleReset = () => {
    setDayById({
      ...dayByID,
      id_daty: "",
      data_pracy: "",
      zmiana: "",
      il_godzin: "",
      id_stawka: "",
    });
    
    setIsSubmitted(null);
  };

  
  useEffect(() => {
    const fetchDates = async () => {
      try {
        const response = await axios.get(`${baseUrl}/getDayByID.php?id=` + id);
        const dayByID = response.data;

        setDayById({
          id_daty: dayByID.id_daty,
          data_pracy: dayByID.data_pracy,
          zmiana: dayByID.zmiana,
          il_godzin: dayByID.il_godzin,
          id_stawka: dayByID.id_stawka,
          typ_stawki: dayByID.typ_stawki,
          stawka: dayByID.stawka,
        });
        
      } catch (error) {
        console.error(error);
      }
    };
    fetchDates();
  }, [id]);

  const submitFormUpdate = (e) => {
    e.preventDefault();

    

    const sendData = {
      id_daty: dayByID.id_daty,
      data_pracy: dayByID.data_pracy,
      zmiana: dayByID.zmiana,
      il_godzin: dayByID.il_godzin,
      id_stawka: dayByID.id_stawka,
    };

    axios
      .post(`${baseUrl}/updateDay.php`, sendData)
      .then((result) => {
        if (result.data.Status === "invalid") {
          alert("Invalid Data");
        } else {
          setIsLoading(true);
          setIsSubmitted(true);
          
          navigate("/alldays");
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
          handleReset();
        }, 1500);
      });
  };

  return (
    <>
      <Header />
      <ScrollButton />
      <MainWrapper>
        <h1 className="text-primary">Update the day of work</h1>
        {isSubmitted ? (
          <h4 className="text-danger">Succes! Day was updated.</h4>
        ) : (
          ""
        )}
        <div className="container">
          {isLoading && (
            <>
              <h4 className="text-danger">Working day update...</h4>
            </>
          )}
          {!isLoading && null}

          <form onSubmit={submitFormUpdate} noValidate>
            <StyledTable className="seldata">
              <tbody>
                <tr>
                  <td className="float-end me-2 text-primary p-2 m-1">
                    Date:{" "}
                  </td>
                  <TdUpdateDay>
                    {" "}
                    <input
                      type="hidden"
                      className="form-control text-primary m-1"
                      name="data_pracy"
                      onChange={handleChange}
                      value={dayByID.data_pracy}
                    />
                    {dayByID.data_pracy}
                  </TdUpdateDay>
                </tr>
                <tr>
                  <td className="float-end me-2 text-primary p-2 m-1">
                    Date ID:{" "}
                  </td>
                  <TdUpdateDay>
                    {" "}
                    <input
                      type="hidden"
                      className="form-control text-primary m-1"
                      name="id_daty"
                      onChange={handleChange}
                      value={dayByID.id_daty}
                    />
                    {dayByID.id_daty}
                  </TdUpdateDay>
                </tr>
                <tr>
                  <td className="float-end me-1 text-primary p-2">
                    Select shift:{" "}
                  </td>
                  <td>
                    <select
                      name="zmiana"
                      className="form-control m-1"
                      value={dayByID.zmiana}
                      onChange={handleChange}
                      autoComplete="off"
                    >
                      <option value={dayByID.zmiana}>{dayByID.zmiana}</option>
                      <option value="1 shift">1 shift</option>
                      <option value="2 shift">2 shift</option>
                      <option value="3 shift">3 shift</option>
                      <option value="6-18">6-18</option>
                      <option value="18-6">18-6</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="float-end me-1 text-primary p-2">
                    Select number of hours:{" "}
                  </td>
                  <td>
                    <select
                      name="il_godzin"
                      className="form-control m-1"
                      value={dayByID.il_godzin}
                      onChange={handleChange}
                      autoComplete="off"
                    >
                      <option value={dayByID.il_godzin}>
                        {dayByID.il_godzin}
                      </option>
                      <option value="8.0">8.0</option>
                      <option value="12.0">12.0</option>
                      <option value="7.0">7.0</option>
                      <option value="7.5">7.5</option>
                      <option value="8.5">8.5</option>
                      <option value="9.0">9.0</option>
                      <option value="9.5">9.5</option>
                      <option value="10.0">10.0</option>
                      <option value="10.5">10.5</option>
                      <option value="11.0">11.0</option>
                      <option value="11.5">11.5</option>
                      <option value="12.5">12.5</option>
                      <option value="1.0">1.0</option>
                      <option value="2.0">2.0</option>
                      <option value="2.5">2.5</option>
                      <option value="3.0">3.0</option>
                      <option value="3.5">3.5</option>
                      <option value="4.0">4.0</option>
                      <option value="4.5">4.5</option>
                      <option value="5.0">5.0</option>
                      <option value="5.5">5.5</option>
                      <option value="6.0">6.0</option>
                      <option value="6.5">6.5</option>
                      <option value="13.0">13.0</option>
                      <option value="13.5">13.5</option>
                      <option value="14.0">14.0</option>
                      <option value="14.5">14.5</option>
                      <option value="15.0">15.0</option>
                      <option value="15.5">15.5</option>
                      <option value="16.0">16.0</option>
                      <option value="16.5">16.5</option>
                      <option value="17.0">17.0</option>
                      <option value="17.5">17.5</option>
                      <option value="18.0">18.0</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="float-end me-1 text-primary p-2">
                    Select rate (PLN):{" "}
                  </td>
                  <td>
                    <select
                      name="id_stawka"
                      className="form-control m-1"
                      value={dayByID.id_stawka}
                      onChange={handleChange}
                      autoComplete="off"
                    >
                      <option value={dayByID.id_stawka}>
                        {dayByID.typ_stawki} - {dayByID.stawka} zł/h
                      </option>
                      <option value="11">regular 33 PLN/h</option>
                      <option value="12">operator 40 PLN/h</option>
                      <option value="13">operator weekend 54 PLN/h</option>
                      <option value="14">correction - regular -33 PLN/h</option>
                      <option value="15">
                        correction - operator -40 PLN/h
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </StyledTable>

            <div>
              <button type="submit" className="btn btn-primary m-2">
                Update
              </button>
            </div>
          </form>
          <BackToListAllDays />
        </div>
      </MainWrapper>
    </>
  );
};

export default EditDay;
