import { toLogin } from "../../App/routes";
import { Link } from "react-router-dom";

const Logout = () => {
  const handleLogout = () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("UserName");
  };

  return (
    <Link to={toLogin} className="nav-link" onClick={handleLogout}>
      Logout
    </Link>
  );
};

export default Logout;
